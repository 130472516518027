import { render, staticRenderFns } from "./budgetDetailInfoAdd.vue?vue&type=template&id=6be5a73c&scoped=true&"
import script from "./budgetDetailInfoAdd.vue?vue&type=script&lang=js&"
export * from "./budgetDetailInfoAdd.vue?vue&type=script&lang=js&"
import style0 from "./budgetDetailInfoAdd.vue?vue&type=style&index=0&id=6be5a73c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be5a73c",
  null
  
)

export default component.exports